.StripePaymentMethodModal {
  align-items: unset;

  .Modal-content {
    position: relative;
    max-height: unset !important;
    margin: 0 !important;
    height: 640px;
    top: max(calc(100vh - (57vh + 390px) + 60px), 60px);
    border-radius: 20px;

    .Modal-header {
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }

    .Modal-footer {
      border-bottom-left-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
    }
  }
}
