.CleaningServiceCalendarDateDisplay {
  .CleaningServiceCalendarDateAddCleaning {
    opacity: 0;
  }
  .CleaningServiceCalendarDateAddCleaning.is-open {
    opacity: 1;
  }
  &:hover {
    .CleaningServiceCalendarDateAddCleaning {
      opacity: 1;
    }
  }
}
