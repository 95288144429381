@use './media';
@use './text-utils';

@each $size, $config in $text-config-lg {
  .is-size-#{$size}.Text.#{$ds-class-name} {
    @include text-utils.specs($config);
  }
}

@each $size, $config in $heading-config-lg {
  .is-size-#{$size}.Text.#{$ds-class-name},
  #{$size}.Text.#{$ds-class-name} {
    @include text-utils.specs($config);
  }
}

@include media.lg {
  @each $size, $config in $text-config-lg {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }

  @each $size, $config in $heading-config-lg {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled,
    #{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }
}

@include media.md {
  @each $size, $config in $text-config-md {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }

  @each $size, $config in $heading-config-md {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled,
    #{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }
}

@include media.sm {
  @each $size, $config in $text-config-sm {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }

  @each $size, $config in $heading-config-sm {
    .is-size-#{$size}.Text.#{$ds-class-name}.is-scaled,
    #{$size}.Text.#{$ds-class-name}.is-scaled {
      @include text-utils.specs($config);
    }
  }
}

.Text {
  &.is-underlined {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &.is-caps {
    text-transform: uppercase;
  }

  &.is-no-wrap {
    white-space: nowrap;
  }

  &.is-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.is-italic {
    font-style: italic;
  }

  &.is-word-break-all {
    word-break: break-all;
  }

  &.is-word-break-word {
    word-break: break-word;
  }

  &.is-white-space-pre {
    white-space: pre;
  }

  &.is-color-blue-gradient {
    background: $blue-gradient;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  &.is-color-blue-gradient-vertical {
    background: linear-gradient(180deg, #3cc8d6 33.9%, #0078ff 116%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  &.is-color-purple-gradient {
    background: $purple-gradient;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  @each $line-count in 1, 2, 3, 4 {
    &.is-max-lines-#{$line-count} {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: $line-count;
      -webkit-line-clamp: $line-count; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}

@each $name, $config in $text-configs {
  .Text.#{$ds-class-name}.is-#{$name} {
    @include text-utils.config-to-rules($config);
  }
}
