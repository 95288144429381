$animation-duration: 500ms;

.nux-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: nux-container-animation-1 $animation-duration ease-out forwards 1500ms,
    nux-container-animation-2 $animation-duration ease-out forwards 3300ms,
    nux-container-animation-3 $animation-duration ease-out forwards 5100ms;

  #hi-user-container {
    animation: hi-user-container-entrance-animation $animation-duration ease-out forwards 0ms,
      hi-user-container-animation 500ms ease-out forwards 1500ms,
      hi-user-container-second-animation $animation-duration ease-out forwards 3300ms;
  }

  #hi-user {
    animation: hi-user-animation $animation-duration ease-out forwards 1500ms;
  }

  #im-your-new-virtual-office-assistant {
    animation: im-your-new-virtual-office-assistant-animation $animation-duration ease-in-out forwards 1400ms,
      im-your-new-virtual-office-assistant-animation-2 $animation-duration ease-out forwards 3300ms,
      im-your-new-virtual-office-assistant-fade-out $animation-duration ease-out forwards 5100ms;
  }

  #explore-a-topic-to-get-started {
    animation: explore-a-topic-to-get-started-animation $animation-duration ease-out forwards 3300ms;
  }

  #nux-card-1 {
    opacity: 0;
    animation: nux-card-stagger-animation-1 $animation-duration ease-in-out forwards 5500ms;
  }
  #nux-card-2 {
    opacity: 0;
    animation: nux-card-stagger-animation-2 $animation-duration ease-in-out forwards 5600ms;
  }
  #nux-card-3 {
    opacity: 0;
    animation: nux-card-stagger-animation-3 $animation-duration ease-in-out forwards 5700ms;
  }
}

@keyframes hi-user-animation {
  0% {
    background: linear-gradient(90deg, #0078ff 0%, #8e5ff4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  10% {
    background: linear-gradient(90deg, #0078ff 0%, #8e5ff4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  60% {
    color: #263238;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
  100% {
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
}

@keyframes hi-user-container-animation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.65);
  }
}

@keyframes hi-user-container-second-animation {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes im-your-new-virtual-office-assistant-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes im-your-new-virtual-office-assistant-animation-2 {
  0% {
    background: linear-gradient(90deg, #0078ff 0%, #8e5ff4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  10% {
    background: linear-gradient(90deg, #0078ff 0%, #8e5ff4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  60% {
    color: #263238;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
  100% {
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
}

@keyframes hi-user-container-entrance-animation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes explore-a-topic-to-get-started-animation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes im-your-new-virtual-office-assistant-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes nux-cards-fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nux-card-stagger-animation-1 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nux-card-stagger-animation-2 {
  0% {
    opacity: 0;
    transform: translateY(28px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nux-card-stagger-animation-3 {
  0% {
    opacity: 0;
    transform: translateY(36px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nux-container-animation-1 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-64px);
  }
}

@keyframes nux-container-animation-2 {
  0% {
    transform: translateY(-64px);
  }
  100% {
    transform: translateY(-128px);
  }
}

@keyframes nux-container-animation-3 {
  0% {
    transform: translateY(-128px);
  }
  100% {
    transform: translateY(-192px);
  }
}

.nux-prompt-card:hover {
  box-shadow: 0px 8px 24px 0px #0000001f !important;
}
