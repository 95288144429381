$blue-900: #001141;
$blue-800: #103084;
$blue-700: #002d9c;
$blue-600: #0043ce;
$blue-500: #0078ff;
$blue-400: #4589ff;
$blue-300: #a6c8ff;
$blue-200: #d0e2ff;
$blue-100: #edf5ff;
$blue-50: #f2f8ff;
$blue-25: #f8fbff;
$true-black: #000000;
$black: #263238;
$gray-950: #14181d;
$gray-900: #263238;
$gray-800: #37474f;
$gray-700: #455a64;
$gray-600: #546e7a;
$gray-500: #607d8b;
$gray-400: #78909c;
$gray-300: #90a4ae;
$gray-200: #b0bec5;
$gray-100: #cfd8dc;
$gray-50: #eceff1;
$gray-25: #fbfbfc;
$white: #ffffff;
$gold-100: #fff8eb;
$gold-200: #fff1d6;
$gold-300: #fee2a9;
$gold-400: #fdcf72;
$gold-500: #fbbb3c;
$gold-600: #db7712;
$gold-700: #b25e09;
$gold-800: #96530f;
$gold-900: #80460d;
$aqua-500: #31e7b6;
$aqua-100: #e6fef8;
$red-100: #fef1f2;
$red-200: #fee1e3;
$red-300: #fec8cd;
$red-400: #fca6ad;
$red-500: #f8727d;
$red-600: #ef4352;
$red-700: #e02d3c;
$red-800: #ba2532;
$red-900: #981b25;
$green-100: #edfdf8;
$green-500: #36d39f;
$green-700: #08875d;
$green-900: #066042;
$purple-400: #ac84ff;
$purple-500: #8e5ff4;
$purple-600: #7744e4;
$purple-700: #612dd0;
$purple-800: #4f1cb9;
$purple-900: #360b8f;

$blue-gradient: linear-gradient(94.99deg, #1ab2d9 28.48%, #0078ff 141.74%);
$purple-gradient: linear-gradient(90deg, #0078ff 0%, #8e5ff4 100%);
$purple-gradient-transparent: linear-gradient(90deg, rgba(0, 120, 255, 0.1) 0%, rgba(142, 95, 244, 0.1) 100%);

$colorToHexMap: (
  'blue-900': $blue-900,
  'blue-800': $blue-800,
  'blue-700': $blue-700,
  'blue-600': $blue-600,
  'blue-500': $blue-500,
  'blue-400': $blue-400,
  'blue-300': $blue-300,
  'blue-200': $blue-200,
  'blue-100': $blue-100,
  'blue-50': $blue-50,
  'blue-25': $blue-25,
  'true-black': $true-black,
  'black': $gray-900,
  'gray-950': $gray-950,
  'gray-900': $gray-900,
  'gray-800': $gray-800,
  'gray-700': $gray-700,
  'gray-600': $gray-600,
  'gray-500': $gray-500,
  'gray-400': $gray-400,
  'gray-300': $gray-300,
  'gray-200': $gray-200,
  'gray-100': $gray-100,
  'gray-50': $gray-50,
  'gray-25': $gray-25,
  'white': $white,
  'gold-100': $gold-100,
  'gold-200': $gold-200,
  'gold-300': $gold-300,
  'gold-400': $gold-400,
  'gold-500': $gold-500,
  'gold-600': $gold-600,
  'gold-700': $gold-700,
  'gold-800': $gold-800,
  'gold-900': $gold-900,
  'aqua-500': $aqua-500,
  'aqua-100': $aqua-100,
  'red-100': $red-100,
  'red-200': $red-200,
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
  'green-100': $green-100,
  'green-500': $green-500,
  'green-700': $green-700,
  'green-900': $green-900,
  'purple-400': $purple-400,
  'purple-500': $purple-500,
  'purple-600': $purple-600,
  'purple-700': $purple-700,
  'purple-800': $purple-800,
  'purple-900': $purple-900
);

@each $color, $hex in $colorToHexMap {
  .bg-color-#{$color} {
    background-color: $hex;
  }

  .bg-color-blue-gradient {
    background: $blue-gradient;
  }

  .hover-bg-color-blue-gradient:hover:not(:active) {
    background: $blue-gradient;
  }

  .active-bg-color-blue-gradient:active {
    background: $blue-gradient;
  }

  .bg-color-purple-gradient {
    background: $purple-gradient;
  }

  .hover-bg-color-purple-gradient:hover:not(:active) {
    background: $purple-gradient;
  }

  .active-bg-color-purple-gradient:active {
    background: $purple-gradient;
  }

  .bg-color-purple-gradient-transparent {
    background: $purple-gradient-transparent;
  }

  .hover-bg-color-purple-gradient-transparent:hover:not(:active) {
    background: $purple-gradient-transparent;
  }

  .active-bg-color-purple-gradient-transparent:active {
    background: $purple-gradient-transparent;
  }

  .hover-bg-color-#{$color}:hover:not(:active) {
    background-color: $hex;
  }

  .active-bg-color-#{$color}:active {
    background-color: $hex;
  }

  .is-color-#{$color} {
    color: $hex;
  }

  ul.is-bullet-color-#{$color} {
    list-style: none;

    li::before {
      content: '•';
      color: $hex;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  .Icon.is-color-#{$color},
  .Spinner.is-color-#{$color},
  .Logo.is-color-#{$color} {
    svg .Icon-stroke-color {
      stroke: $hex;
    }

    svg .Icon-fill-color {
      fill: $hex;
    }
  }

  .Blob.is-color-#{$color},
  .Svg.is-color-#{$color} {
    svg .Svg-fill-color {
      fill: $hex;
    }
  }

  .is-border-color-#{$color} {
    border-color: $hex;
  }

  @each $side in top, right, bottom, left {
    .layout-has-border-#{$side}-#{$color} {
      border-#{$side}: 1px solid $hex;
    }
  }

  .is-active-border-color-#{$color}:active {
    border-color: $hex;
  }

  .is-focus-border-color-#{$color}:focus {
    border-color: $hex;
  }

  .hover-text-color-#{$color}:hover {
    color: $hex;

    .Text {
      color: $hex;
    }
  }

  .hover-icon-color-#{$color}:hover {
    .Icon svg .Icon-stroke-color {
      stroke: $hex;
    }

    .Icon svg .Icon-fill-color {
      fill: $hex;
    }
  }
}

.is-border-color-transparent {
  border-color: transparent;
}

.is-border-color-blue-gradient {
  position: relative;

  ::before {
    content: '';
    position: absolute;
    inset: 0;
    padding: 2px;
    background: linear-gradient(94.99deg, #1ab2d9 28.48%, #0078ff 141.74%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  @each $width in 0, 1, 2, 4, 8 {
    &.is-border-width-#{$width} {
      border-width: 0;

      ::before {
        padding: #{$width}px;
      }
    }
  }

  @each $value in 0, 2, 4, 8, 12, 16, 20, 24, 36, 40, 56, 80, 100, 136, 1000 {
    &.is-border-radius-#{$value} {
      ::before {
        border-radius: #{$value}px;
      }
    }
  }

  &.is-border-radius-circular {
    ::before {
      border-radius: 50%;
    }
  }
}
