.layout-flex {
  display: flex;
}

.layout-flex-wrap {
  flex-wrap: wrap;
}

.layout-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.layout-flex-equal-width {
  flex: 1 1 0;
}

.user-select-none {
  user-select: none;
}

@each $value in block, grid, inline, inline-block, inline-flex, none {
  .layout-display-#{$value} {
    display: $value;
  }
}

@each $value in visible, hidden, collapse {
  .layout-visibility-#{$value} {
    visibility: $value;
  }
}

@each $overflow in overflow, overflow-x, overflow-y {
  @each $value in hidden, visible, auto, scroll {
    .layout-#{$overflow}-#{$value} {
      #{$overflow}: $value;
    }
  }
}

@each $align in flex-start, flex-end, center, baseline, stretch {
  .layout-align-#{$align} {
    align-items: $align;
  }
}

@each $direction in column, column-reverse, row, row-reverse {
  .layout-direction-#{$direction} {
    flex-direction: $direction;
  }
}

@each $justify in flex-start, flex-end, center, space-between, space-around, space-evenly {
  .layout-justify-#{$justify} {
    justify-content: $justify;
  }
}

@each $position in static, absolute, relative, fixed, sticky {
  .layout-position-#{$position} {
    position: $position;
  }
}

@each $opacity in 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 {
  .layout-hover-opacity-#{$opacity} {
    &:hover {
      opacity: $opacity / 100 !important;
    }
  }
}
