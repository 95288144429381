$ds-class-name: 'codi_ds';

$font-family: Inter, sans-serif;

$header-z-index: 2000;
$modal-z-index: $header-z-index + 20;

$input-border-radius: 8px;
$border-radius: 12px;
$border-radius-img: 40px;

$page-margin-xs: 24px;
$page-margin-sm: 40px;

$pill-button-height-sm: 24px;
$pill-button-height-md: 36px;
$pill-button-height-lg: 48px;

$bottom-navigation-height: 60px;

$field-height-xxs: 32px;
$field-height-xs: 36px;
$field-height-sm: 48px;
$field-height-md: 56px;
$field-height-lg: 64px;

$field-font-size-xxs: 12px;
$field-font-size-xs: 12px;
$field-font-size-sm: 14px;
$field-font-size-md: 14px;
$field-font-size-lg: 16px;

$field-line-height-xxs: 16px;
$field-line-height-xs: 16px;
$field-line-height-sm: 16px;
$field-line-height-md: 20px;
$field-line-height-lg: 20px;

$small-label-font-size-xxs: 10px;
$small-label-font-size-xs: 10px;
$small-label-font-size-sm: 10px;
$small-label-font-size-md: 10px;
$small-label-font-size-lg: 12px;

$small-label-line-height-xxs: 12px;
$small-label-line-height-xs: 12px;
$small-label-line-height-sm: 12px;
$small-label-line-height-md: 16px;
$small-label-line-height-lg: 18px;

$field-padding-x-xxs: 12px;
$field-padding-x-xs: 12px;
$field-padding-x-sm: 16px;
$field-padding-x-md: 16px;
$field-padding-x-lg: 24px;

$input-error-font-size-xxs: 12px;
$input-error-font-size-xs: 12px;
$input-error-font-size-sm: 12px;
$input-error-font-size-md: 12px;
$input-error-font-size-lg: 12px;

$icon-size-xxs: 12px;
$icon-size-xs: 16px;
$icon-size-sm: 20px;
$icon-size-md: 24px;
$icon-size-lg: 28px;
$icon-size-xl: 32px;
$icon-size-xxl: 36px;
$icon-size-xxxl: 66px;

$icon-button-size-xs: 24px;
$icon-button-size-sm: 32px;
$icon-button-size-md: 40px;
$icon-button-size-lg: 48px;

$field-visual-value-gap-xxs: 8px;
$field-visual-value-gap-xs: 8px;
$field-visual-value-gap-sm: 8px;
$field-visual-value-gap-md: 8px;
$field-visual-value-gap-lg: 8px;

$field-image-size-xxs: 24px;
$field-image-size-xs: 24px;
$field-image-size-sm: 24px;
$field-image-size-md: 24px;
$field-image-size-lg: 24px;

$field-clear-size-xxs: 16px;
$field-clear-size-xs: 16px;
$field-clear-size-sm: 16px;
$field-clear-size-md: 20px;
$field-clear-size-lg: 24px;

$screen-xs: 375px;
$screen-sm: 768px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1680px;

// sync with ds/constants.ts
$input-border-width: 1px;
$input-focus-border-width: 2px;
$input-error-border-width: 2px;

$spinner-size-xxs: 16px;
$spinner-size-xs: 24px;
$spinner-size-sm: 32px;
$spinner-size-md: 40px;
$spinner-size-lg: 50px;

$checkbox-dimension-sm: 16px;
$checkbox-dimension-md: 18px;
$checkbox-dimension-lg: 20px;

$radio-button-dimension-sm: 16px;
$radio-button-dimension-md: 18px;
$radio-button-dimension-lg: 20px;

$location-results-min-width-sm: 336px;
$location-results-min-width-md: 408px;
$location-results-min-width-lg: 432px;

$typeahead-item-height-xxs: 32px;
$typeahead-item-height-xs: 36px;
$typeahead-item-height-sm: 40px;
$typeahead-item-height-md: 56px;
$typeahead-item-height-lg: 56px;

$typeahead-result-font-size-xxs: 10px;
$typeahead-result-font-size-xs: 12px;
$typeahead-result-font-size-sm: 12px;
$typeahead-result-font-size-md: 14px;
$typeahead-result-font-size-lg: 14px;

$typeahead-results-min-width-xxs: 336px;
$typeahead-results-min-width-xs: 336px;
$typeahead-results-min-width-sm: 336px;
$typeahead-results-min-width-md: 408px;
$typeahead-results-min-width-lg: 432px;

$control-font-weight: 600;

$text-button-padding-sm: 4px;
$text-button-padding-md: 8px;
$text-button-padding-lg: 12px;

$toggle-width-sm: 48px;
$toggle-width-md: 64px;
$toggle-width-lg: 64px;

$toggle-height-sm: 24px;
$toggle-height-md: 32px;
$toggle-height-lg: 32px;

$toggle-padding-x-sm: 2px;
$toggle-padding-x-md: 4px;
$toggle-padding-x-lg: 4px;

$toggle-padding-y-sm: 2px;
$toggle-padding-y-md: 4px;
$toggle-padding-y-lg: 4px;

$calendar-day-dimension: 32px;
$calendar-day-gutter-x: 9px;
$calendar-day-gutter-y: 8px;

$field-size-config: (
  xxs: (
    height: $field-height-xxs,
    font-size: $field-font-size-xxs,
    line-height: $field-line-height-xxs,
    small-label-line-height: $small-label-line-height-xxs,
    small-label-font-size: $small-label-font-size-xxs,
    padding-x: $field-padding-x-xxs,
    icon-size: $icon-size-xxs,
    image-size: $field-image-size-xxs,
    visual-value-gap: $field-visual-value-gap-xxs,
    clear-size: $field-clear-size-xs,
    inner-padding-x: $field-padding-x-xxs,
    inner-padding-x-with-icon: $field-padding-x-xxs + $icon-size-xxs + $field-visual-value-gap-xxs,
    inner-padding-x-with-image: $field-padding-x-xxs + $field-image-size-xxs + $field-visual-value-gap-xxs,
    input-error-font-size: $input-error-font-size-xxs,
    typeahead-item-height: $typeahead-item-height-xxs,
    typeahead-result-font-size: $typeahead-result-font-size-xxs,
    typeahead-results-min-width: $typeahead-results-min-width-xxs
  ),
  xs: (
    height: $field-height-xs,
    font-size: $field-font-size-xs,
    line-height: $field-line-height-xs,
    small-label-line-height: $small-label-line-height-xs,
    small-label-font-size: $small-label-font-size-xs,
    padding-x: $field-padding-x-xs,
    icon-size: $icon-size-xs,
    image-size: $field-image-size-xs,
    visual-value-gap: $field-visual-value-gap-xs,
    clear-size: $field-clear-size-xs,
    inner-padding-x: $field-padding-x-xs,
    inner-padding-x-with-icon: $field-padding-x-xs + $icon-size-xs + $field-visual-value-gap-xs,
    inner-padding-x-with-image: $field-padding-x-xs + $field-image-size-xs + $field-visual-value-gap-xs,
    input-error-font-size: $input-error-font-size-xs,
    checkbox-dimension: $checkbox-dimension-sm,
    radio-button-dimension: $radio-button-dimension-sm,
    location-results-min-width: $location-results-min-width-sm,
    typeahead-item-height: $typeahead-item-height-xs,
    typeahead-result-font-size: $typeahead-result-font-size-xs,
    typeahead-results-min-width: $typeahead-results-min-width-xs,
    text-button-padding: $text-button-padding-sm,
    toggle-width: $toggle-width-sm,
    toggle-height: $toggle-height-sm,
    toggle-padding-x: $toggle-padding-x-sm,
    toggle-padding-y: $toggle-padding-y-sm
  ),
  sm: (
    height: $field-height-sm,
    font-size: $field-font-size-sm,
    line-height: $field-line-height-sm,
    small-label-line-height: $small-label-line-height-sm,
    small-label-font-size: $small-label-font-size-sm,
    padding-x: $field-padding-x-sm,
    icon-size: $icon-size-sm,
    image-size: $field-image-size-sm,
    visual-value-gap: $field-visual-value-gap-sm,
    clear-size: $field-clear-size-sm,
    inner-padding-x: $field-padding-x-sm,
    inner-padding-x-with-icon: $field-padding-x-sm + $icon-size-sm + $field-visual-value-gap-sm,
    inner-padding-x-with-image: $field-padding-x-sm + $field-image-size-sm + $field-visual-value-gap-sm,
    input-error-font-size: $input-error-font-size-sm,
    checkbox-dimension: $checkbox-dimension-sm,
    radio-button-dimension: $radio-button-dimension-sm,
    location-results-min-width: $location-results-min-width-sm,
    typeahead-item-height: $typeahead-item-height-sm,
    typeahead-result-font-size: $typeahead-result-font-size-sm,
    typeahead-results-min-width: $typeahead-results-min-width-sm,
    text-button-padding: $text-button-padding-sm,
    toggle-width: $toggle-width-sm,
    toggle-height: $toggle-height-sm,
    toggle-padding-x: $toggle-padding-x-sm,
    toggle-padding-y: $toggle-padding-y-sm
  ),
  md: (
    height: $field-height-md,
    font-size: $field-font-size-md,
    line-height: $field-line-height-md,
    small-label-line-height: $small-label-line-height-md,
    small-label-font-size: $small-label-font-size-md,
    padding-x: $field-padding-x-md,
    icon-size: $icon-size-md,
    image-size: $field-image-size-md,
    visual-value-gap: $field-visual-value-gap-md,
    clear-size: $field-clear-size-md,
    inner-padding-x: $field-padding-x-md,
    inner-padding-x-with-icon: $field-padding-x-md + $icon-size-md + $field-visual-value-gap-md,
    inner-padding-x-with-image: $field-padding-x-md + $field-image-size-md + $field-visual-value-gap-md,
    input-error-font-size: $input-error-font-size-md,
    checkbox-dimension: $checkbox-dimension-md,
    radio-button-dimension: $radio-button-dimension-md,
    location-results-min-width: $location-results-min-width-md,
    typeahead-item-height: $typeahead-item-height-md,
    typeahead-result-font-size: $typeahead-result-font-size-md,
    typeahead-results-min-width: $typeahead-results-min-width-md,
    text-button-padding: $text-button-padding-md,
    toggle-width: $toggle-width-md,
    toggle-height: $toggle-height-md,
    toggle-padding-x: $toggle-padding-x-md,
    toggle-padding-y: $toggle-padding-y-md
  ),
  lg: (
    height: $field-height-lg,
    font-size: $field-font-size-lg,
    line-height: $field-line-height-lg,
    small-label-line-height: $small-label-line-height-lg,
    small-label-font-size: $small-label-font-size-lg,
    padding-x: $field-padding-x-lg,
    icon-size: $icon-size-lg,
    image-size: $field-image-size-lg,
    visual-value-gap: $field-visual-value-gap-lg,
    clear-size: $field-clear-size-lg,
    inner-padding-x: $field-padding-x-lg,
    inner-padding-x-with-icon: $field-padding-x-lg + $icon-size-lg + $field-visual-value-gap-lg,
    inner-padding-x-with-image: $field-padding-x-lg + $field-image-size-lg + $field-visual-value-gap-lg,
    input-error-font-size: $input-error-font-size-lg,
    checkbox-dimension: $checkbox-dimension-lg,
    radio-button-dimension: $radio-button-dimension-lg,
    location-results-min-width: $location-results-min-width-lg,
    typeahead-item-height: $typeahead-item-height-lg,
    typeahead-result-font-size: $typeahead-result-font-size-lg,
    typeahead-results-min-width: $typeahead-results-min-width-lg,
    text-button-padding: $text-button-padding-lg,
    toggle-width: $toggle-width-lg,
    toggle-height: $toggle-height-lg,
    toggle-padding-x: $toggle-padding-x-lg,
    toggle-padding-y: $toggle-padding-y-lg
  )
);

$text-display-lg: (
  font-size: 80px,
  line-height: 84px
);

$text-display-md: (
  font-size: 64px,
  line-height: 70px
);

$text-display-sm: (
  font-size: 48px,
  line-height: 56px
);

$text-headline-xl: (
  font-size: 40px,
  line-height: 48px
);

$text-headline-lg: (
  font-size: 32px,
  line-height: 42px
);

$text-headline-md: (
  font-size: 24px,
  line-height: 28px
);

$text-headline-sm: (
  font-size: 20px,
  line-height: 24px
);

$text-headline-xs: (
  font-size: 18px,
  line-height: 22px
);

$text-body-xl: (
  font-size: 32px,
  line-height: 42px
);

$text-body-lg: (
  font-size: 20px,
  line-height: 30px
);

$text-body-md: (
  font-size: 16px,
  line-height: 24px,
  letter-spacing: 0.32px
);

$text-body-sm: (
  font-size: 14px,
  line-height: 20px,
  letter-spacing: 0.28px
);

$text-body-xs: (
  font-size: 12px,
  line-height: 16px,
  letter-spacing: 0.24px
);

$text-overline-lg: (
  font-size: 18px,
  line-height: 20px,
  letter-spacing: 3.6px,
  font-weight: 700
);

$text-overline-md: (
  font-size: 12px,
  line-height: 16px,
  letter-spacing: 2.4px,
  font-weight: 700
);

$text-overline-sm: (
  font-size: 10px,
  line-height: 14px,
  letter-spacing: 2px,
  font-weight: 700
);

$text-tag: (
  font-size: 10px,
  line-height: 12px,
  letter-spacing: 0.2px
);

$text-link-lg: (
  font-size: 16px,
  line-height: 24px,
  letter-spacing: 0.32px
);

$text-link-md: (
  font-size: 14px,
  line-height: 20px,
  letter-spacing: 0.28px
);

$text-link-sm: (
  font-size: 12px,
  line-height: 16px,
  letter-spacing: 0.24px
);

$heading-config-sm: (
  h1: (
    font-size: 48px,
    line-height: 64px,
    letter-spacing: -0.01em,
    font-weight: 400
  ),
  h2: (
    font-size: 32px,
    line-height: 40px,
    font-weight: 400
  ),
  h3: (
    font-size: 24px,
    line-height: 36px,
    font-weight: 400
  ),
  h4: (
    font-size: 20px,
    line-height: 32px,
    font-weight: 400
  ),
  h5: (
    font-size: 18px,
    line-height: 28px,
    font-weight: 400
  ),
  h6: (
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.02em,
    font-weight: 400
  )
);

$text-config-sm: (
  body1: (
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body2: (
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body3: (
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  link: (
    font-size: 14px,
    font-weight: 600,
    line-height: 20px,
    letter-spacing: 0.02em
  ),
  large-link: (
    font-size: 16px,
    font-weight: 600,
    line-height: 24px,
    letter-spacing: 0.02em
  ),
  paragraph: (
    font-size: 14px,
    line-height: 24px,
    font-weight: 400,
    letter-spacing: 0.02em
  ),
  quotation: (
    font-size: 16px,
    font-weight: 800,
    line-height: 24px
  ),
  tag: (
    font-size: 10px,
    font-weight: 600,
    line-height: 14px
  ),
  label: (
    font-size: 10px,
    font-weight: 500,
    line-height: 15px
  )
);

$heading-config-md: (
  h1: (
    font-size: 72px,
    line-height: 90px,
    letter-spacing: -0.02em,
    font-weight: 400
  ),
  h2: (
    font-size: 48px,
    line-height: 64px,
    letter-spacing: -0.01em,
    font-weight: 400
  ),
  h3: (
    font-size: 32px,
    line-height: 40px,
    font-weight: 400
  ),
  h4: (
    font-size: 24px,
    line-height: 36px,
    font-weight: 400
  ),
  h5: (
    font-size: 18px,
    line-height: 28px,
    font-weight: 400
  ),
  h6: (
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.02em,
    font-weight: 400
  )
);

$text-config-md: (
  body1: (
    font-size: 14px,
    line-height: 24px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body2: (
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body3: (
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  link: (
    font-size: 14px,
    font-weight: 600,
    line-height: 20px,
    letter-spacing: 0.02em
  ),
  large-link: (
    font-size: 16px,
    font-weight: 600,
    line-height: 24px,
    letter-spacing: 0.02em
  ),
  paragraph: (
    font-size: 14px,
    line-height: 24px,
    font-weight: 400,
    letter-spacing: 0.02em
  ),
  quotation: (
    font-size: 16px,
    font-weight: 800,
    line-height: 24px
  ),
  tag: (
    font-size: 10px,
    font-weight: 600,
    line-height: 16px
  ),
  label: (
    font-size: 10px,
    font-weight: 500,
    line-height: 15px
  )
);

$heading-config-lg: (
  h1: $text-display-lg,
  h2: $text-display-md,
  h3: $text-display-sm,
  h4: $text-headline-lg,
  h5: $text-headline-md,
  h6: $text-headline-xs
);

$text-config-lg: (
  body1: (
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body2: (
    font-size: 14px,
    line-height: 20px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  body3: (
    font-size: 12px,
    line-height: 16px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  link: (
    font-size: 16px,
    font-weight: 600,
    line-height: 24px,
    letter-spacing: 0.02em
  ),
  large-link: (
    font-size: 18px,
    font-weight: 600,
    line-height: 24px,
    letter-spacing: 0.02em
  ),
  paragraph: (
    font-size: 16px,
    line-height: 24px,
    letter-spacing: 0.02em,
    font-weight: 400
  ),
  quotation: (
    font-size: 24px,
    font-weight: 800,
    line-height: 36px
  ),
  tag: (
    font-size: 10px,
    font-weight: 600,
    line-height: 16px
  ),
  label: (
    font-size: 12px,
    font-weight: 500,
    line-height: 18px
  )
);

$text-configs: (
  display-lg: $text-display-lg,
  display-md: $text-display-md,
  display-sm: $text-display-sm,
  headline-xl: $text-headline-xl,
  headline-lg: $text-headline-lg,
  headline-md: $text-headline-md,
  headline-sm: $text-headline-sm,
  headline-xs: $text-headline-xs,
  body-xl: $text-body-xl,
  body-lg: $text-body-lg,
  body-md: $text-body-md,
  body-sm: $text-body-sm,
  body-xs: $text-body-xs,
  overline-lg: $text-overline-lg,
  overline-md: $text-overline-md,
  overline-sm: $text-overline-sm,
  tag: $text-tag,
  link-lg: $text-link-lg,
  link-md: $text-link-md,
  link-sm: $text-link-sm
);
