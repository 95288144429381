h1,
h2,
h3,
h4,
h5,
h6 {
  &.Text {
    // Override Antd
    margin-bottom: 0;
  }

  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  color: unset;
  text-decoration: none;
}
