.Button.#{$ds-class-name} {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }

  &.is-size-joinCircle {
    height: $field-height-xxs;
    padding: 0 6px;
  }

  &.is-size-pill-sm {
    height: $pill-button-height-sm;
    padding: 0 12px;
    font-size: 12px;
    line-height: 12px;
  }

  &.is-size-pill-md {
    height: $pill-button-height-md;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
  }

  &.is-size-pill-lg {
    height: $pill-button-height-lg;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
  }

  &.is-size-xxs {
    height: $field-height-xxs;
    padding: 0 12px;

    &.has-icon.icon-position-right {
      padding: 0 8px 0 12px;
    }

    &.has-icon.icon-position-left {
      padding: 0 12px 0 8px;
    }

    .Button-buttonText {
      font-size: $field-font-size-xxs;
      line-height: $field-font-size-xxs;
    }
  }

  &.is-size-xs {
    height: $field-height-xs;
    padding: 0 16px;

    &.has-icon.icon-position-right {
      padding: 0 12px 0 16px;
    }

    &.has-icon.icon-position-left {
      padding: 0 16px 0 12px;
    }

    .Button-buttonText {
      font-size: $field-font-size-xs;
      line-height: $field-font-size-xs;
    }
  }

  &.is-size-sm {
    height: $field-height-sm;
    padding: 0 24px;

    &.has-icon.icon-position-right {
      padding: 0 16px 0 24px;
    }

    &.has-icon.icon-position-left {
      padding: 0 24px 0 16px;
    }

    .Button-buttonText {
      font-size: $field-font-size-sm;
      line-height: $field-font-size-sm;
    }
  }

  &.is-size-md {
    height: $field-height-md;
    padding: 0 32px;

    &.has-icon.icon-position-right {
      padding: 0 24px 0 32px;
    }

    &.has-icon.icon-position-left {
      padding: 0 32px 0 24px;
    }

    .Button-buttonText {
      font-size: $field-font-size-md;
      line-height: $field-font-size-md;
    }
  }

  &.is-size-lg {
    height: $field-height-lg;
    padding: 0 48px;

    &.has-icon.icon-position-right {
      padding: 0 36px 0 48px;
    }

    &.has-icon.icon-position-left {
      padding: 0 48px 0 36px;
    }

    .Button-buttonText {
      font-size: $field-font-size-lg;
      line-height: $field-font-size-lg;
    }
  }

  &.is-size-xxs .Button-iconContainer,
  &.is-size-xs .Button-iconContainer {
    &.is-left {
      margin-right: 4px;
    }

    &.is-right {
      margin-left: 4px;
    }
  }

  &.is-size-sm .Button-iconContainer,
  &.is-size-md .Button-iconContainer,
  &.is-size-lg .Button-iconContainer {
    &.is-left {
      margin-right: 8px;
    }

    &.is-right {
      margin-left: 8px;
    }
  }

  &.has-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.13);
    transition: box-shadow 200ms, background-color 200ms;
  }

  &:hover.has-shadow {
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
  }

  &:active.has-shadow {
    box-shadow: 0 7px 11px rgba(0, 0, 0, 0.2);
  }

  &.is-fullWidth {
    width: 100%;
  }
}

.Button-iconContainer {
  display: inline-flex;
}

.Button-buttonTextContainer {
  display: inline-flex;
  justify-content: center;
}

.Button-buttonTextContainer,
.Button-buttonText {
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
}

.Button.is-semibold {
  .Button-buttonText {
    font-weight: $control-font-weight;
  }
}

.Button-spinnerContainer {
  flex-shrink: 0;
  width: 100%;
  position: relative;

  &.is-size-xs {
    height: $spinner-size-xs;
  }

  &.is-size-sm {
    height: $spinner-size-sm;
  }
}

.Button-spinner {
  position: absolute;

  &.is-size-xs {
    margin-left: calc(50% - #{$spinner-size-xs/2});
  }

  &.is-size-sm {
    margin-left: calc(50% - #{$spinner-size-sm/2});
  }
}

.Button.has-spinner {
  .Button-buttonText,
  .Button-buttonTextContainer,
  .Button-iconContainer {
    visibility: hidden;
  }
}

a.Button {
  text-decoration: none;
}

a.Button.#{$ds-class-name}:hover.has-hover-style {
  opacity: unset;
}
