.RichTextDisplay {
  &.is-max-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @each $line-count in 1, 2, 3, 4 {
    &.max-line-count-#{$line-count} {
      -webkit-line-clamp: $line-count;
    }
  }
}
